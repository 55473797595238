<template>
  <base-section id="k-d-a-project-detail-list" space="180" top-inner-shadow>
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col>
          <component
            :is="m_objTitle.strComponent"
            :class="`kda-ts-${g_bLowerBr ? '28' : '54'}pt wt-extrabold ln-150 text-center`"
            v-html="m_objTitle.htmlText"
          />
        </v-col>
      </v-row>

      <v-row no-gutters justify="center" class="pt-10 px-4" style="gap: 56px">
        <template v-if="!g_bLowestBr">
          <v-col v-for="project in m_arrProjects" :key="project.strTitle" cols="auto">
            <v-card
              max-width="326"
              elevation="3"
              height="100%"
              tile
              color="white"
              class="cursor-pointer"
              @click="navToDetailProject(project.strLink)"
            >
              <p class="kda-ts-16pt wt-bold nunito main-bw-400--text px-3 pt-4 pb-3">
                {{ project.strType }}
              </p>

              <base-img
                :src="project.imgSrc"
                contain
                style="max-height: 280px"
                class="py-4"
              ></base-img>

              <div class="px-8 py-4">
                <h4 class="kda-ts-28pt wt-extrabold mb-4">{{ project.strTitle }}</h4>

                <p class="kda-ts-16pt ln-150 nunito main-bw-300--text">{{ project.strDesc }}</p>
              </div>
            </v-card>
          </v-col>
        </template>

        <v-col v-else>
          <v-card flat tile>
            <v-window v-model="iProjectIndex" class="mb-2">
              <v-window-item v-for="n in m_arrProjects.length" :key="`card-${n}`">
                <v-card
                  color="grey"
                  height="280"
                  width="326"
                  class="mx-auto mb-2"
                  elevation="4"
                  @click="navToDetailProject(m_arrProjects[iProjectIndex].strLink)"
                >
                  <base-img :src="m_arrProjects[iProjectIndex].imgSrc" contain>
                    <div
                      class="fill-height full-width d-flex align-end"
                      style="background-image: linear-gradient(#00000000, #000000b4) !important"
                    >
                      <h1 class="white--text kda-ts-28pt wt-extrabold pa-4">
                        {{ m_arrProjects[iProjectIndex].strTitle }}
                      </h1>
                    </div>
                  </base-img>
                </v-card>
              </v-window-item>
            </v-window>

            <v-card-actions class="justify-space-between">
              <v-item-group v-model="iProjectIndex" class="text-center mx-auto my-2" mandatory>
                <v-item
                  v-for="n in m_arrProjects.length"
                  :key="`window-btn-slider-${n}`"
                  v-slot="{ active, toggle }"
                >
                  <v-btn :input-value="active" icon x-small @click="toggle">
                    <v-icon small>mdi-record</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-card-actions>
          </v-card>

          <div class="d-flex justify-center align-center">
            <v-btn icon elevation="1" class="main-bw-300 mr-4">
              <v-icon color="white">mdi-arrow-right</v-icon>
            </v-btn>

            <p class="kda-ts-16pt wt-semibold nunito">{{ m_strButtonMore }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDAProjectDetailList',

  mixins: [TextClass, BusinessDetails, BaseBreakpoint],

  data() {
    return { iProjectIndex: 0 };
  },

  computed: {
    m_objTitle() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.project.explore'),
        strComponent: 'h1'
      };
    },
    m_strButtonMore() {
      return 'Lihat Lebih Banyak Proyek';
    },
    m_arrProjects() {
      const projectsName = ['WayFinder', 'AXA', 'Container Loader'];
      const projectsLink = ['wayfinder', 'axa', 'load-container'];
      const result = [];

      for (let i = 1; i <= projectsName.length; i++) {
        result.push({
          strType: this.$vuetify.lang.t(`$vuetify.kda.home.projects.item${i}.type`),
          strTitle: projectsName[i - 1],
          strLink: projectsLink[i - 1],
          strDesc: this.$vuetify.lang.t(`$vuetify.kda.home.projects.item${i}.desc`),
          imgSrc: require(`@/assets/photos/home-project-${i}.png`)
        });
      }

      return result;
    }
  },

  methods: {
    navToDetailProject(link) {
      link = `/project/detail/${link}`;
      if (this.$route.fullPath !== link) {
        this.$router.push(link);
      }
    }
  }
};
</script>
